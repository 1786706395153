import JSEncrypt from "./JSEncrypt/jsencrypt.min";

export default {

  encryptWithRsaPublicKeyAndNonce(rsaPublicKey, nonce, plaintext) {
    let jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(rsaPublicKey);

    let appendedText = nonce.slice(0, 5) + plaintext;
    let encryptedPassword = jsEncrypt.encrypt(appendedText);

    return {encryptedPassword, nonce}
  },

  encryptWithRsaPublicKey(rsaPublicKey, plaintext) {
    let jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(rsaPublicKey);
    return jsEncrypt.encrypt(plaintext)
  },

  async getNonceAndRsaPublicKey($cppClient, $feedback) {
    const apiUrl = "nonce/get";
    try {
      let res = await $cppClient.post(
        apiUrl,
        {}
      );
      await $feedback.hideLoading();
      let response = res.data;
      let {publicKey, nonce} = response;
      return {publicKey, nonce};
    } catch ({response}) {
      await $feedback.showFailed(response);
      throw new Error("CRYPTO_API_MALFUNCTIONED");
    }
  }

}

